import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/esm/Button"
import Card from "react-bootstrap/esm/Card"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import { Page } from "../../components/Page"
import { saveDraft } from "../../db"
import { createSignature } from "../../helpers/signMessage"
import { WriterItem } from "../../components/WriterItem"

// Fireworks
let set = 0
const createFirework = (event: any) => {
  const xPos = event.clientX
  const yPos = event.clientY
  const colour = "#" + Math.random().toString(16).substr(2, 6)

  // Create 50 divs, start them on top of each other
  // so they can radiate out from the centre
  for (let i = 1; i <= 50; i++) {
    const firework = document.createElement("div")
    firework.className = "firework"
    firework.classList.add(`firework${i}`)
    firework.classList.add(`set${set}`)
    firework.style.backgroundColor = colour
    firework.style.left = xPos + "px"
    firework.style.top = yPos + "px"
    document.body.appendChild(firework)
  }

  set += 1
}
const deleteFirework = () => {
  const setToDelete = set - 3
  if (set >= 0) {
    const oldFireworks = document.querySelectorAll(`.set${setToDelete}`)

    oldFireworks.forEach(firework => {
      firework.remove()
    })
  }
}

const getPost = async (postId: string) => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}getDocument/${postId}`
  )
  try {
    const result = await resultRes.json()
    if (result.error) {
      throw Error(result.error)
    }
    return {
      title: result.title,
      content: result.content,
      contentHash: result.contentHash,
      createTime: result.createTime,
      signature: result.signature,
      slug: result.slug,
      publicKey: result.wallet,
      amountUpvotes: result.amountUpvotes,
      writer: result.writer,
    }
  } catch (e) {
    return {
      title: "Not found",
      content: "<p>There is no post here...",
      contentHash: "",
      createTime: 0,
      signature: "",
      publicKey: "",
      slug: "",
      amountUpvotes: 0,
    }
  }
}

export default function Post({ params }: { params: { id: string } }) {
  const [amountUpvotes, setAmountUpvotes] = useState("")
  const [title, setTitle] = useState("")
  const [slug, setSlug] = useState("")
  const [content, setContent] = useState("")
  const [contentHash, setContentHash] = useState("")
  const [createTime, setCreateTime] = useState(0)
  const [signature, setSignature] = useState("")
  const [articlePublicKey, setPublicKey] = useState("")
  const [writer, setWriter] = useState<{
    writer: string
    label?: string
    picture?: string
  }>()
  const [upvoteMessage, setUpvoteMessage] = useState("")

  const { publicKey, signMessage } = useWallet()
  const { connection } = useConnection()
  const wallet = useAnchorWallet()

  useEffect(() => {
    getPost(params.id).then(result => {
      console.log(result)
      setTitle(result.title)
      setContent(result.content)
      setCreateTime(result.createTime)
      setSignature(result.signature)
      setPublicKey(result.publicKey)
      setContentHash(result.contentHash)
      setSlug(result.slug)
      setAmountUpvotes(result.amountUpvotes)
      setWriter(
        result.writer
          ? {
              writer: result.writer.twitterHandle,
              label: result.writer.label,
              picture: result.writer.picture,
            }
          : {
              writer: result.publicKey,
            }
      )
    })
  }, [])

  const editPost = async () => {
    // Create draft
    const id = Math.random()
    await saveDraft(id, title, content)

    // Route
    navigate(`/editor/${id}`)
  }

  const deletePost = async () => {
    const useLedger =
      localStorage.getItem("useLedger") === "true" ? true : false
    let signature
    try {
      signature = await createSignature(
        connection,
        wallet,
        publicKey,
        signMessage,
        `Delete ${slug} created on ${createTime}`
      )
    } catch (e: any) {
      // Do nothing
      return
    }

    const res = await fetch(`${process.env.GATSBY_BACKEND_ENDPOINT}document`, {
      method: "DELETE",
      body: JSON.stringify({
        createTime,
        slug,
        signature,
        publicKey: (publicKey || "").toString(),
        ledger: useLedger,
      }),
    })
    const result = await res.json()
    if (result.status === "success") {
      window.location.reload()
    }
  }

  const upvote = async (event: any) => {
    const useLedger =
      localStorage.getItem("useLedger") === "true" ? true : false
    // Sign upvote
    const signature = await createSignature(
      connection,
      wallet,
      publicKey,
      signMessage,
      `Upvote ${slug}`
    )

    // Do API call
    const res = await fetch(`${process.env.GATSBY_BACKEND_ENDPOINT}upvote`, {
      method: "POST",
      body: JSON.stringify({
        slug,
        signature,
        publicKey: (publicKey || "").toString(),
        ledger: useLedger,
      }),
    })
    const result = await res.json()

    if (result.status === "success") {
      setAmountUpvotes(amountUpvotes + 1)
      deleteFirework()
      createFirework(event)
    } else {
      setUpvoteMessage("Already upvoted")
    }
  }

  return (
    <>
      <Page>
        <h3>
          {(publicKey || "").toString() === articlePublicKey && (
            <Button
              size="sm"
              onClick={editPost}
              style={{ marginRight: "10px" }}
            >
              Copy as draft
            </Button>
          )}
          {process.env
            .GATSBY_ADMIN_LIST!.split(",")
            .includes((publicKey || "").toString()) &&
            articlePublicKey && (
              <Button
                size="sm"
                variant="danger"
                onClick={deletePost}
                style={{ marginRight: "10px" }}
              >
                Delete
              </Button>
            )}
          {title}
        </h3>
        {articlePublicKey && (
          <Card className="documentCard">
            <Card.Header>
              <strong>Writer</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col>
                    {writer && (
                      <WriterItem
                        writer={writer.writer}
                        label={writer.label}
                        picture={writer?.picture}
                      />
                    )}
                    {publicKey && (
                      <Button onClick={upvote}>
                        {amountUpvotes || 0}{" "}
                        <i className="bi bi-hand-thumbs-up"></i>
                      </Button>
                    )}
                    <p>{upvoteMessage ? upvoteMessage : ""}</p>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        )}
        <div
          className="documentContent"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        {articlePublicKey && (
          <div className="documentProofs">
            <hr />
            <em>
              Written by {articlePublicKey} on{" "}
              {new Date(createTime).toISOString()}
            </em>
            <br />
            <em>Content hash (sha256): {contentHash}</em>
            <br />
            <em>Content signature: {signature}</em>
          </div>
        )}
      </Page>
    </>
  )
}
